import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { HeaderHero, NavBarColorSwitcher, Spacer, WrapperBody } from "components";
export const _frontmatter = {
  "title": "Privacy Policy",
  "navBarColor": "light"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HeaderHero title="Privacy Policy" mdxType="HeaderHero" />
    <NavBarColorSwitcher color="dark" mdxType="NavBarColorSwitcher">
	<WrapperBody mdxType="WrapperBody">
		<h2>Who we are</h2>
		<p>
			Our website address is:{" "}
			<a href="https://departmentofweb.com">
				https://departmentofweb.com{" "}
			</a>
		</p>
		<h2>What personal data we collect and why we collect it</h2>
		<h3>Contact forms</h3>
		<p>
			We are not using the information submitted via the contact form on
			our website for any marketing purposes. Via contact form we are
			processing your first and last name and email and we are processing
			it for customer support purposes and provision of high-quality IT
			services only.
		</p>
		<h3>Cookies</h3>
		<p>
			If you leave a comment on our site you may opt-in to saving your
			name, email address and website in cookies. These are for your
			convenience so that you do not have to fill in your details again
			when you leave another comment. These cookies will last for one
			year.
		</p>
		<p>
			If you have an account and you log in to this site, we will set a
			temporary cookie to determine if your browser accepts cookies. This
			cookie contains no personal data and is discarded when you close
			your browser.
		</p>
		<p>
			When you log in, we will also set up several cookies to save your
			login information and your screen display choices. Login cookies
			last for two days, and screen options cookies last for a year. If
			you select “Remember Me”, your login will persist for two weeks. If
			you log out of your account, the login cookies will be removed.
		</p>
		<p>
			If you edit or publish an article, an additional cookie will be
			saved in your browser. This cookie includes no personal data and
			simply indicates the post ID of the article you just edited. It
			expires after 1 day.
		</p>
		<h3>Embedded content from other websites</h3>
		<p>
			Articles on this site may include embedded content (e.g. videos,
			images, articles, etc.). Embedded content from other websites
			behaves in the exact same way as if the visitor has visited the
			other website.
		</p>
		<p>
			These websites may collect data about you, use cookies, embed
			additional third-party tracking, and monitor your interaction with
			that embedded content, including tracing your interaction with the
			embedded content if you have an account and are logged in to that
			website.
		</p>
		<h3>Analytics</h3>
		<p>We are using Google Analytics.</p>
		<p>
			Google Analytics is a web analysis service provided by Google Inc.
			(“Google”). Google utilizes the Data collected to track and examine
			the use of this Application, to prepare reports on its activities
			and share them with other Google services.
		</p>
		<p>
			Google may use the Data collected to contextualize and personalize
			the ads of its own advertising network.
		</p>
		<p>Personal Data collected: Cookies and Usage Data.</p>
		<p>
			Place of processing: United States –{" "}
			<a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noreferrer">
				Privacy Policy
			</a>{" "}
			-{" "}
			<a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noreferrer">
				Opt Out.
			</a>{" "}
			Privacy Shield participant.
		</p>
		<h2>Who we share your data with</h2>
		<p>We are sharing your data with:</p>
		<ul>
			<li>Google</li>
			<li>SendInBlue</li>
			<li>Facebook</li>
		</ul>
		<h2>How long we retain your data</h2>
		<p>
			If you leave a comment, the comment and its metadata are retained
			indefinitely. This is so we can recognize and approve any follow-up
			comments automatically instead of holding them in a moderation
			queue.
		</p>
		<p>
			For users that register on our website (if any), we also store the
			personal information they provide in their user profile. All users
			can see, edit, or delete their personal information at any time
			(except they cannot change their username). Website administrators
			can also see and edit that information.
		</p>
		<h2>What rights you have over your data</h2>
		<p>
			If you have an account on this site, or have left comments, you can
			request to receive an exported file of the personal data we hold
			about you, including any data you have provided to us. You can also
			request that we erase any personal data we hold about you. This does
			not include any data we are obliged to keep for administrative,
			legal, or security purposes.
		</p>
	</WrapperBody>
    </NavBarColorSwitcher>
    <Spacer height={180} responsive={{
      md: 140,
      sm: 100
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      